import { Avatar, Col, Dropdown, MenuProps, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import BrandSentiment from './components/BrandSentiment/BrandSentiment'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PortfolioShareChart from './components/PortfolioShareChart/PortfolioShareChart'
import PlatformDistributionCard from './components/PlatformDistributionCard/PlatformDistributionCard'
import BrandsListVertical from './components/BrandsListVertical/BrandsListVertical'
import MediaTypeSelector from './components/MediaTypeSelector/MediaTypeSelector'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as PowerInsightsIcon } from 'assets/images/icons/powerinsight_icon.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/icons/expand.svg'
import { ReactComponent as ThreeDotsIcon } from 'assets/images/three-dots.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import InfluencerTable from './components/InfluencerTable/InfluencerTable'
import SuggestedIssues from './components/SuggestedIssues/SuggestedIssues'
import FollowedIssues from './components/FollowedIssues/FollowedIssues'
import DailyExecutiveSummary from './components/DailyExecutiveSummary/DailyExecutiveSummary'
import SnippetsDiscovery from './components/SnippetsDiscovery/SnippetsDiscovery'
import { DownloadOption } from './components/DownloadOption'
import { FlagListObjType } from 'store/flags/types'
import { MonitorGraph } from './components/MonitorGraph/MonitorGraph'
import PowerInsightTabsListVertical from './components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'
import classNames from 'classnames'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'
import millify from 'millify'
import { DetailsObjType } from 'types/types'
import { FilterChips } from 'components/Monitor/FilterChips/FilterChips'
import { IBarChartData } from 'models/models'
import './BrandDashboard.scss'

const BRANDS = 'Brands'
const customGraphColors = {
  shadeColorUp: '#eeeff4',
  lineColorUp: '#A84BD3',
  shadeColorDown: '#eeeff4',
  lineColorDown: '#A84BD3',
  theme: 'purple',
}

const BrandDashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showBrandList, setShowBrandList] = useState(false)
  const [isOpenExecutiveSummaryModal, setIsOpenExecutiveSummaryModal] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [itemToView, setItemToView] = useState<FlagListObjType | IBarChartData | undefined>()
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('')
  const { assetsStore, tenantsStore, loaderStore } = store
  const {
    fetchPowerInsightsForDashboardPage,
    assetsLoading,
    assetsList,
    snippetsFilter,
    snippetsTotal,
    resetPowerInsightsData,
    fetchAssetsList,
    assetsCategories,
    fetchCategories,
    fetchSnippets,
    setSnippetsFilter,
    fetchPowerInsights,
    resetSnippetFilters,
  } = assetsStore
  const { customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore
  const { statsLineChartLoading, isLoadingFeed } = loaderStore

  const [selectedBrand, setSelectedBrand] = useState<AssetListObjType>()

  const isPowerInsightsMode = location.pathname?.includes('/brand/power-insights')

  const detailObject = {
    name: selectedBrand?.name,
    conditions: selectedBrand?.conditions,
    mode: 'asset',
    id: selectedBrand?.id,
    activeTab: selectedPowerInsightTab,
  }

  useEffectOnce(() => {
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    if (!customThemeLoading && !isCorporateCommunicationsTheme) {
      navigate('/')
    }
  }, [isCorporateCommunicationsTheme, customThemeLoading])

  useEffect(() => {
    if (assetsCategories.length === 0) return
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    const params: AssetListParamsType = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory?.category_id}`,
    }
    fetchAssetsList({ params: params })
  }, [assetsCategories])

  useEffect(() => {
    if (!assetsLoading && assetsList.items.length > 0 && !selectedBrand) {
      const brand = assetsList.items.filter((brand) => brand.is_active)[0]
      if (brand) setSelectedBrand(brand)
    }
    if (isPowerInsightsMode) return
    if (!assetsLoading && assetsList.items.length === 0) resetPowerInsightsData()
  }, [assetsList])

  useEffect(() => {
    if (isPowerInsightsMode) fetchSnippets()
    if (isPowerInsightsMode) return
    if (selectedBrand) {
      fetchPowerInsightsForDashboardPage({
        name: selectedBrand.name,
        conditions: selectedBrand.conditions,
        mode: 'asset',
        id: selectedBrand.id,
      })
    }
  }, [selectedBrand, snippetsFilter])

  useEffect(() => {
    if (isPowerInsightsMode && selectedBrand?.id) fetchPowerInsights(detailObject as DetailsObjType)
  }, [snippetsFilter, selectedBrand])

  if (assetsLoading || customThemeLoading) {
    return (
      <div className='spinner-container'>
        <Spin />
      </div>
    )
  }

  const openExectuiveSummaryModal = () => setIsOpenExecutiveSummaryModal(true)
  const closeExecutiveSummaryModal = () => setIsOpenExecutiveSummaryModal(false)
  const openSnippetsDiscovery = () => setIsOpenSnippetsDiscovery(true)
  const closeSnippetsDiscovery = () => {
    if (itemToView) setItemToView(undefined)
    resetSnippetFilters()
    setIsOpenSnippetsDiscovery(false)
  }

  const onSelectFlag = (flag: FlagListObjType) => {
    setSnippetsFilter({ ...snippetsFilter, flags: { name: flag.name, conditions: flag?.conditions || {} } })
    setItemToView(flag)
    openSnippetsDiscovery()
  }

  const onSelectWatchlist = (watchlist: IBarChartData) => {
    setSnippetsFilter({ ...snippetsFilter, watchlist: watchlist.channel_urls })
    setItemToView({ ...watchlist, name: watchlist.entity_name })
    openSnippetsDiscovery()
  }

  const buttonHandler = (event: string) => {
    // if (event === 'SHARE') {
    //   setIsShareModalOpen(true)
    // }
    // if (event === 'EDIT') {
    //   setOpenEditModal(true)
    // }
    // if (event === 'ALERT') {
    //   setEntityId(selectedBrand?.id || '')
    //   setAlertId(selectedBrand?.alert_id || '')
    // }
    // if (event === 'DELETE') {
    //   setOpenDeleteModal(true)
    // }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => buttonHandler('SHARE'),
      label: (
        <div className='pi-dropdown-item'>
          <ShareIcon />
          <span>Share</span>
        </div>
      ),
    },
    {
      key: '2',
      onClick: () => buttonHandler('EDIT'),
      label: (
        <div className='pi-dropdown-item'>
          <EditIcon />
          <span>Edit Name</span>
        </div>
      ),
    },
    {
      key: '3',
      onClick: () => buttonHandler('ALERT'),
      label: (
        <div className='pi-dropdown-item'>
          {selectedBrand?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          <span>Alerts</span>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='pi-dropdown-item'>
          <ReportIcon />
          <span>Export</span>
        </div>
      ),
    },
    {
      key: '5',
      onClick: () => buttonHandler('DELETE'),
      label: (
        <div className='pi-dropdown-item'>
          <DeleteIcon />
          <span>Delete</span>
        </div>
      ),
    },
  ]

  return (
    <div className='brand-dashboard'>
      <BrandsListVertical
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        showBrandList={showBrandList}
      />

      <div className='brand-dashboard-stats'>
        <MediaTypeSelector />
        <div className={classNames('filters-section', { powerinsight_mood: isPowerInsightsMode })}>
          <div onClick={() => setShowBrandList(!showBrandList)} className='brand-selected'>
            <Avatar src={profileImage} />
            <h6 className='title'>{selectedBrand?.name}</h6>
          </div>
          <div className='executive-summary-btn' onClick={openExectuiveSummaryModal}>
            <ExecutiveSummaryIcon />
            Executive summary
            <ExpandIcon />
          </div>
          {isPowerInsightsMode && (
            <div className='power-insights-options'>
              <span>Power Insights</span>
              <Dropdown menu={{ items }} placement='bottomLeft'>
                <ThreeDotsIcon />
              </Dropdown>
            </div>
          )}
          <div className='date-filter'>
            <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'assets'} addCustomDateOption={false} />
            {!isPowerInsightsMode && (
              <ButtonImage onClick={openSnippetsDiscovery} className='fhc_sc_button'>
                Show all snippets
              </ButtonImage>
            )}
            {!isPowerInsightsMode && (
              <ButtonImage
                onClick={() => {
                  setSnippetsFilter({
                    ...snippetsFilter,
                    flags: { name: selectedBrand?.name || '', conditions: selectedBrand?.conditions || {} },
                  })
                  navigate('power-insights')
                }}
                className='fhc_pi_button'
                icon={PowerInsightsIcon}>
                Power Insights
              </ButtonImage>
            )}
            {isPowerInsightsMode && (
              <ButtonImage
                onClick={() => {
                  resetSnippetFilters()
                  navigate('/brand')
                }}
                className='fhc_sc_button bt_dashboard'
                icon={LeftArrowIcon}>
                Back to dashboard
              </ButtonImage>
            )}
          </div>
          {isOpenExecutiveSummaryModal && <DailyExecutiveSummary onClose={closeExecutiveSummaryModal} />}
          {isOpenSnippetsDiscovery && (
            <SnippetsDiscovery onClose={closeSnippetsDiscovery} selectedItem={selectedBrand} itemToView={itemToView} />
          )}
        </div>
        {selectedBrand && !isPowerInsightsMode && (
          <div>
            <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
              <Col span={18}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Row className='dashboard-widgets__card__sub-row'>
                      <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                        <MentionViewCard
                          selectedItem={selectedBrand}
                          type='Mentions'
                          key='Mentions'
                          customGraphColors={customGraphColors}
                          chartWrapperClassName='cards-chart-wrapper'
                        />
                      </Col>
                      <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                        <MentionViewCard
                          selectedItem={selectedBrand}
                          type='Views'
                          key='Views'
                          measure='impression'
                          customGraphColors={customGraphColors}
                          chartWrapperClassName='cards-chart-wrapper'
                        />
                      </Col>
                      <Col span={8} className='dashboard-widgets__card mention-view-widgets'>
                        <MentionViewCard
                          selectedItem={selectedBrand}
                          type='Engagement'
                          key='Engagement'
                          measure='engagement'
                          customGraphColors={customGraphColors}
                          chartWrapperClassName='cards-chart-wrapper'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <SuggestedIssues selectedItem={selectedBrand} />
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <FollowedIssues selectedBrand={selectedBrand} onSelectFlag={onSelectFlag} />
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <InfluencerTable onRowClick={onSelectWatchlist} />
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row gutter={[0, 24]}>
                  <Col span={24} className='dashboard-widgets__card'>
                    <PortfolioShareChart />
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <BrandSentiment setIsOpenSnippetsDiscovery={setIsOpenSnippetsDiscovery} />
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <div id='trendChart'>
                      <div className='chart-heading'>
                        <span className='monitor-assets__graph__title'>Current Trend</span>
                        <DownloadOption elementToCapture='#trendChart' />
                      </div>
                      <MonitorGraph
                        subStore='assets'
                        dataLoading={statsLineChartLoading}
                        resizeChartTrigger={showBrandList}
                        selectedItem={selectedBrand}
                      />
                    </div>
                  </Col>
                  <Col span={24} className='dashboard-widgets__card'>
                    <PlatformDistributionCard
                      isVerticalDisplay
                      setIsOpenSnippetsDiscovery={setIsOpenSnippetsDiscovery}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        {isPowerInsightsMode && (
          <Row
            gutter={24}
            justify={'start'}
            align={'top'}
            className={classNames('dashboard-widgets', { powerinsight_dashboard: isPowerInsightsMode })}>
            <Col style={{ width: '226', minWidth: '221px' }}>
              <PowerInsightTabsListVertical setSelectedPowerInsightTab={setSelectedPowerInsightTab} />
            </Col>
            <Col style={{ flex: 1 }}>
              <Row gutter={[24, 24]} className='power-insights-and-snippets'>
                <Col span={16} className='power-insights-container'>
                  <Row>
                    <Col span={24} className='power-insights-components'>
                      <Outlet
                        context={{
                          selectedBrand,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8} className='snippet-section-column'>
                  <Spin spinning={isLoadingFeed} wrapperClassName='snippet-section-loader'>
                    <Row>
                      <Col span={24} className='dashboard-snippet-section'>
                        <div className='dashboard-snippet-section__container'>
                          <div className='dashboard-snippet-section__container__top-container'>
                            <div className='dashboard-snippet-section__container__header'>
                              <p className='dashboard-snippet-section__container__header__title'>Snippets Feed</p>
                              {!!snippetsTotal && (
                                <p className='dashboard-snippet-section__container__header__count'>
                                  {millify(snippetsTotal || 0)} snippets
                                </p>
                              )}
                            </div>
                            <SnippetFilter
                              mode={'asset'}
                              activeTab={''}
                              page={'assets'}
                              pageType={'list'}
                              subStore={'assets'}
                              showDashboardActions
                              searchPlaceholder='Search Posts...'
                            />
                            <FilterChips
                              subStore={'assets'}
                              detailObj={detailObject as DetailsObjType}
                              activePowerInsightTab={selectedPowerInsightTab}
                            />
                          </div>
                          <SnippetList
                            mode={'asset'}
                            isDashboardSnippet
                            showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                            searchedDataResults={snippetsTotal}
                            subStore={'assets'}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Spin>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default observer(BrandDashboard)
