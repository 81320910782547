import { Form, Input } from 'antd'

const AddDetails = () => {
  return (
    <div className='add-details'>
      <label>
        <span className='input-label'>Watchlist name</span>
        <Form.Item name='name' rules={[{ required: true, message: 'Please input watchlist name' }]}>
          <Input placeholder='Kinetic Risk' key={'watchlist-add-details-name-input'} />
        </Form.Item>
      </label>
      <label>
        <span className='input-label'>Description</span>
        <Form.Item name='description'>
          <Input.TextArea placeholder='Kinetic Risk' key={'watchlist-add-details-description-textarea'} />
        </Form.Item>
      </label>
    </div>
  )
}

export default AddDetails
