import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import dayjs from 'dayjs'
import MainViewHeader from 'components/Investigate/Reports/MianViewHeader/MainViewHeader'
import Table, { IColumnType } from 'components/common/Table/Table'
import Dropdown from 'components/Investigate/Reports/Dropdown/Dropdown'
import NextUpdate from 'components/Investigate/Reports/NextUpdate/NextUpdate'
import { ReactComponent as GenerateIcon } from 'assets/images/icons/report/generate-icon-size-16.svg'
import styles from './InvestigateReportsMainViewPage.module.scss'
import { useNavigate } from 'react-router-dom'
import ActionButtons from 'components/Investigate/Reports/ActionButtons/ActionButtons'
import ReportModal from 'components/common/Modal/ReportModal'
import SuccessBodyModal from 'components/common/Modal/SuccessBodyModal/SuccessBodyModal'
import { ReactComponent as EdittIcon } from 'assets/images/icons/editreport.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info-tooltip-icon.svg'
import successGif from 'assets/gifs/reportLoader.gif'
import deleteGif from 'assets/gifs/sectiondeleted.gif'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import DeleteReportModal from 'components/Investigate/Reports/DeleteReportModal/DeleteReportModal'
import { Spin } from 'antd'
import { LastUpdateObj, ListsObj } from 'store/report/valueTypes'
import usePermissions from 'hooks/usePermissions'
import EmptyState from './EmptyState'
const TABLE_DATE_FORMATE = 'MMM DD, YYYY'
// const TABLE_RECRRING_DATE_FORMATE = 'MMM DD YYYY'

export type SharedUsersObj = {
  user_id: string
  username?: string
  given_name: string
  family_name: string
  image_url: string
  permission_level: string
}

const reportType = {
  ADHOC: 'Single',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
}

type LastUpdatedComponentProps = {
  column: IColumnType<ListsObj>
  item: ListsObj
}

const LastUpdatedComponent = (props: LastUpdatedComponentProps) => {
  const navigate = useNavigate()
  const { item } = props
  const { report_instance_history } = item
  const [lastUpdate, setLastUpdate] = useState<LastUpdateObj>({})
  return (
    <Dropdown
      data={report_instance_history}
      showIcon={report_instance_history.length > 1 ? true : false}
      value={
        Object.keys(lastUpdate).length
          ? dayjs(lastUpdate.end_date).format(TABLE_DATE_FORMATE)
          : report_instance_history.length
          ? dayjs(report_instance_history[0].end_date).format(TABLE_DATE_FORMATE)
          : '-'
      }
      onSelect={(res: LastUpdateObj) => {
        setLastUpdate(res)
        if (report_instance_history[0].instance_id !== res.instance_id) {
          navigate(`/reports/${item.id}?instance_id=${res.instance_id}`)
        }
      }}
    />
  )
}

const InvestigateReportsMainViewPage = observer(() => {
  const navigate = useNavigate()

  const { reportMainViewStore } = store

  const { reports, isLoadingReports, getReports, updateReportHandler } = reportMainViewStore

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSuccessModalVisible, setIsSuccesModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isDeleteSuccessVisible, setIsDeleteSuccessVisible] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [reportId, setReportId] = useState<string>('')

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  //.........search reports..........
  const [search, setSearch] = useState<string>('')
  const [filterSearch, setFilterSearch] = useState<string>('')

  const searchReportOnChange = (e: any) => {
    setSearch(e.target.value)
  }
  const filterHandler = () => {
    setFilterSearch(search)
  }

  const clearSearchHandler = () => {
    setSearch('')
    setFilterSearch('')
  }

  const searchedReports = useMemo(() => {
    if (filterSearch && reports.items.length) {
      return reports.items.filter((report) => {
        return report.name.toLowerCase().includes(filterSearch.toLowerCase())
      })
    }
    return reports.items
  }, [filterSearch, reports])

  //.......................

  useEffect(() => {
    getReports()
  }, [])

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const closeCreateAndOpenSuccessModal = (data: any) => {
    updateReportHandler(reportId, data).then((res) => {
      if (res === 'error') {
        return
      }
      getReports()
      setIsSuccesModalVisible(true)
      setIsModalVisible(false)
      setTimeout(closeSuccesModal, 5000)
    })
  }

  const closeSuccesModal = () => {
    setIsSuccesModalVisible(false)
  }
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const closeDeleteAndOpenSuccessModal = () => {
    setIsDeleteSuccessVisible(true)
    setIsDeleteModalVisible(false)
    getReports()
  }
  const closeDeleteSuccess = () => {
    setIsDeleteSuccessVisible(false)
  }

  const columns: IColumnType<ListsObj>[] = [
    {
      key: 'name',
      title: 'Name',
      width: 310,
      render: (column, item) => {
        const { name, generating_report, created } = item
        const findLast48Hours = Math.abs(new Date().valueOf() - new Date(created).valueOf()) / 36e5

        return (
          <div className={styles.report_name_container}>
            <p className={styles.report_name}>{name}</p>
            {findLast48Hours < 48 && <p className={styles.report_new}>NEW</p>}
            {generating_report && (
              <div className={styles.rnc_generate_icon_container}>
                <GenerateIcon className={styles.rnc_generate_icon} />
              </div>
            )}
          </div>
        )
      },
    },
    {
      key: 'last_update',
      title: 'Last updated',
      width: 150,
      render: (column, item) => {
        return <LastUpdatedComponent column={column} item={item} />
      },
    },
    {
      key: 'next_update',
      title: 'Next update',
      width: 150,
      render: (column, item) => {
        const { next_report_date } = item

        return (
          <>
            {next_report_date ? (
              <NextUpdate value={next_report_date ? dayjs(next_report_date).format(TABLE_DATE_FORMATE) : '-'} />
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      key: 'report_type',
      title: 'Report Type',
      width: 150,
      render: (column, item) => {
        const { report_type, weekly_report_day } = item

        return (
          <div className={styles.report_type_container}>
            <span> {reportType[report_type as keyof typeof reportType] || '-'}</span>
            {reportType[report_type as keyof typeof reportType] === reportType.WEEKLY && (
              <div className={styles.rtc_icon_container} data-tooltip={weekly_report_day}>
                <InfoIcon className={styles.rtc_ic_icon} />
              </div>
            )}
          </div>
        )
      },
    },
    // {
    //   key: 'recurring',
    //   title: 'Recurring',
    //   width: 150,
    //   render: (column, item) => {
    //     const { weekly_report_day, report_instance_history, report_type } = item
    //     if (report_type === 'ADHOC') {
    //       return (
    //         <span className={styles.rmvc_recurring}>
    //           {dayjs(report_instance_history[0].start_date).format(TABLE_RECRRING_DATE_FORMATE)} {' - '}
    //           {dayjs(report_instance_history[0].end_date).format(TABLE_RECRRING_DATE_FORMATE)}
    //         </span>
    //       )
    //     }
    //     return <span>{weekly_report_day || '-'}</span>
    //   },
    // },
    {
      key: 'created_on',
      title: 'Created on',
      width: 150,
      render: (column, item) => {
        const { created } = item
        return <span className={styles.rmvc_created_date}>{dayjs(created).format(TABLE_DATE_FORMATE)}</span>
      },
    },
    // {
    //   key: 'shared_with',
    //   title: 'Shared with',
    //   width: 150,
    //   render: (column, item) => {
    //     const { shared_users } = item
    //     return <>{shared_users.length ? <ShareWith data={shared_users} /> : '-'}</>
    //   },
    // },
    {
      key: 'actions',
      title: 'Actions',
      width: 150,
      render: (column, item) => {
        const { id } = item

        return (
          <>
            {!canReviewAndReceive && (
              <ActionButtons
                showTooltip={true}
                className={styles.action_button_container}
                hideExtraIcons={true}
                deleteHandler={() => {
                  setReportId(id)
                  setIsDeleteModalVisible(true)
                }}
                shareHandler={() => {
                  setReportId(id)
                  setIsShareModalOpen(true)
                }}
                editHandler={() => {
                  setReportId(id)
                  setIsModalVisible(true)
                }}
              />
            )}
          </>
        )
      },
    },
  ]

  const selectRowHandler = (item: ListsObj) => {
    if (item.report_instance_history?.length) {
      navigate(`/reports/${item.id}?instance_id=${item.report_instance_history[0].instance_id}`)
    }
  }

  const disabledRowHandler = (item: ListsObj) => {
    if (!item.report_instance_history?.length) {
      return true
    }
    return false
  }

  return (
    <div className={styles.reports_main_view_container}>
      <Spin size='large' spinning={isLoadingReports}>
        <MainViewHeader
          className={styles.rmvc_header}
          value={search}
          searchReportOnChange={searchReportOnChange}
          onPressEnter={filterHandler}
          onClear={clearSearchHandler}
          reportsExist={searchedReports.length > 0}
        />
        {searchedReports.length === 0 && <EmptyState />}
        {searchedReports.length > 0 && (
          <Table
            data={searchedReports}
            columns={columns}
            className={styles.rmvc_table}
            onRowClick={selectRowHandler}
            disabledRow={disabledRowHandler}
          />
        )}
      </Spin>

      {/* ...........the edit report button in table ................... */}
      {isModalVisible && (
        <ReportModal
          reportId={reportId}
          editMode={true}
          isModalVisible
          closeModal={closeModal}
          closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
          icon={<EdittIcon />}
          title='Edit Report'
          description='Create and schedule your report by adding multiple items.'
          footerButtonText='Update'
        />
      )}
      {isSuccessModalVisible && (
        <SuccessBodyModal
          visibilityController={isSuccessModalVisible}
          gif={successGif}
          heading='Report is updating ...'
          subheading='it can take up to 2 minutes for updating a report'
        />
      )}
      {/* delete part */}
      {isDeleteModalVisible && (
        <DeleteReportModal reportId={reportId} onClose={closeDeleteModal} onDelete={closeDeleteAndOpenSuccessModal} />
      )}

      {isDeleteSuccessVisible && (
        <SuccessBodyModal
          isDelete={true}
          visibilityController={isDeleteSuccessVisible}
          gif={deleteGif}
          heading='Report Deleted'
          subheading=''
          closeDeleteSuccess={closeDeleteSuccess}
          buttonText='Close'
        />
      )}
      {isShareModalOpen && (
        <ShareReportModal
          reportId={reportId}
          endpoint='reports'
          callback={getReports}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </div>
  )
})

export default InvestigateReportsMainViewPage
