import { Form, Input } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

const CreateEditWatchlistHeader = ({ verifyAndGetPlatform }: { verifyAndGetPlatform: () => void }) => {
  return (
    <div className='watchlist-builder__section--center__header'>
      <Form.Item name='url' rules={[{ required: true, message: 'Please input the link ' }]}>
        <Input
          key={'watchlist-header-url-input'}
          size='large'
          placeholder='Paste the link here...'
          prefix={<LinkOutlined />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              verifyAndGetPlatform()
            }
          }}
        />
      </Form.Item>
    </div>
  )
}

export default CreateEditWatchlistHeader
