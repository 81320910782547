import { useState } from 'react'
import { Col, Popover, Row } from 'antd'
import Input from 'components/common/Inputs/Input/Input'
import StepsHeader from '../components/StepsHeader'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import StepsBanner from '../components/StepsBanner'
import { ReactComponent as PuzzleIcon } from 'assets/images/icons/report/puzzle.svg'
import { ReactComponent as AddIcon } from 'assets/images/add-circle-icon.svg'
import { ReactComponent as RemoveIcon } from 'assets/images/remove-icon.svg'
import ContentSelector from '../components/ContentSelector/ContentSelector'
import { SectionObjType } from '../CreateReport'
import ContentSource from '../components/ContentFilters/ContentSource'
import Sentiment from '../components/ContentFilters/Sentiment'
import PlatformDistribution from '../components/ContentFilters/PlatformDistribution'
import Language from '../components/ContentFilters/Language'
import { useEffectOnce } from 'react-use'
import './commonStyles.scss'

const DESCRIPTION =
  "Customize the type of content you'd like to receive by selecting from the options below. Each part represents a specific content stream that will help guide and personalize the information delivered to you."

const contentFilters = ['Content source', 'Language', 'Platforms', 'Sentiment']

type Props = {
  editMode?: boolean
  section: SectionObjType
  updateSection: (sections: SectionObjType) => void
  errors: any
  setErrors: (errors: any) => void
}

export const ContentForm = ({ editMode, section, updateSection, errors, setErrors }: Props) => {
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false)
  const {
    filters: { source, language, platform, sentiment },
  } = section

  useEffectOnce(() => {
    let count = 0
    Object.keys(section.filters).forEach((filter) => {
      const filterKey = filter as keyof typeof section.filters
      count = count + section.filters[filterKey].length
    })

    Object.keys(section.optionalItems).forEach((filter) => {
      const filterKey = filter as keyof typeof section.optionalItems
      count = count + section.optionalItems[filterKey].length
    })
    if (count) setShowAdvanceFilters(true)
  })

  const filterComponent = (contentFilterType: string) => {
    const onChangeContentSource = (event: any, value: string) => {
      if (!event.target.checked && source.includes(value)) {
        const newSources = source.filter((item: any) => value !== item)
        updateSection({ ...section, filters: { ...section.filters, source: newSources } })
      } else {
        const newSources = [...source, value]
        updateSection({ ...section, filters: { ...section.filters, source: newSources } })
      }
    }

    const onChangeSentimentFilter = (event: any, value: string) => {
      if (!event.target.checked && sentiment.includes(value)) {
        const newSources = sentiment.filter((filter: any) => filter !== value)
        updateSection({ ...section, filters: { ...section.filters, sentiment: newSources } })
      } else {
        const newSources = [...sentiment, value]
        updateSection({ ...section, filters: { ...section.filters, sentiment: newSources } })
      }
    }

    const onChangePlatformFilter = (event: any, value: string) => {
      if (!event.target.checked && platform.includes(value)) {
        const newSources = platform.filter((filter: any) => filter !== value)
        updateSection({ ...section, filters: { ...section.filters, platform: newSources } })
      } else {
        const newSources = [...platform, value]
        updateSection({ ...section, filters: { ...section.filters, platform: newSources } })
      }
    }

    const onChangeLanguageFilter = (event: any, value: string) => {
      if (!event.target.checked && language.includes(value)) {
        const newSources = language.filter((filter) => value !== filter)
        updateSection({ ...section, filters: { ...section.filters, language: newSources } })
      } else {
        const newSources = [...language, value]
        updateSection({ ...section, filters: { ...section.filters, language: newSources } })
      }
    }

    switch (contentFilterType) {
      case 'Content source':
        return <ContentSource selected={source} setSelected={onChangeContentSource} />
      case 'Sentiment':
        return <Sentiment selected={sentiment} setSelected={onChangeSentimentFilter} />
      case 'Platforms':
        return <PlatformDistribution selected={platform} setSelected={onChangePlatformFilter} />
      case 'Language':
        return <Language selected={language} setSelected={onChangeLanguageFilter} />
      default:
        return null
    }
  }

  const contentFilterState = (contentFilterType: string) => {
    switch (contentFilterType) {
      case 'Content source':
        return source
      case 'Sentiment':
        return sentiment
      case 'Platforms':
        return platform
      case 'Language':
        return language
      default:
        return []
    }
  }

  const removeFilter = (event: any, contentFilterType: string) => {
    event.stopPropagation()
    switch (contentFilterType) {
      case 'Content source':
        updateSection({ ...section, filters: { ...section.filters, source: [] } })
        break
      case 'Sentiment':
        updateSection({ ...section, filters: { ...section.filters, sentiment: [] } })
        break
      case 'Platforms':
        updateSection({ ...section, filters: { ...section.filters, platform: [] } })
        break
      case 'Language':
        updateSection({ ...section, filters: { ...section.filters, language: [] } })
        break
      default:
        return null
    }
  }

  const onChangeSectionName = (event: any) => {
    if (errors.contentTitle && event.target.value) {
      const { contentTitle, ...existingErrors } = errors
      setErrors(existingErrors)
    }
    updateSection({ ...section, title: event.target.value })
  }

  return (
    <>
      <StepsHeader title='Content' stepText={editMode ? 'Step 1 of 2' : 'Step 2'} description={DESCRIPTION} />
      <div className='content-select__title-input'>
        <Input placeholder='Name this Section' value={section.title} onChange={onChangeSectionName} />
        {errors.contentTitle && <span className='content-select__title-input__error'>{errors.contentTitle}</span>}
      </div>
      <div className='content-select'>
        <div className='content-select__header'>
          <div className='content-select__header__content'>
            <h6 className='title'>Select content</h6>
            <p className='description'>
              Choose the content sources you want to include in your report. Content from any of these sources will be
              included.
            </p>
            <ContentSelector
              key='mainSelector'
              items={section.mainItems}
              setItems={(items) => {
                updateSection({ ...section, mainItems: items })
              }}
            />
          </div>
        </div>
      </div>
      {!showAdvanceFilters && (
        <ButtonImage onClick={() => setShowAdvanceFilters(true)} className='advance-filter-btn'>
          Advanced filters
        </ButtonImage>
      )}
      {showAdvanceFilters && (
        <div className='content-advance-filters'>
          <div className='content-filters-linked'>
            <div className='link' />
            <div className='content-select'>
              <div className='content-select__header'>
                <div className='content-select__header__content'>
                  <h6 className='title'>Refine your content</h6>
                  <p className='description'>
                    Narrow down your content by selecting additional criteria below. This ensures your report includes
                    only items that match both your selections above and these additional criteria.
                  </p>
                  <ContentSelector
                    key='optionalSelector'
                    items={section.optionalItems}
                    setItems={(items) => {
                      updateSection({ ...section, optionalItems: items })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='content-filters-linked'>
            <div className='link' />
            <div className='content-select'>
              <div className='content-select__header'>
                <div className='content-select__header__content'>
                  <h6 className='title'>Apply additional filters</h6>
                  <p className='description'>
                    Apply additional filters to the content, such as keywords, content source and other attributes.
                  </p>
                  <div className='filters-list'>
                    {contentFilters.map((content) => (
                      <Popover
                        content={filterComponent(content)}
                        trigger='click'
                        overlayClassName='content-filters-list-item__popover'
                        placement='topRight'
                        destroyTooltipOnHide
                        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}>
                        <div className='content-filters-list-item__container'>
                          <div className='content-filters-list-item__info'>
                            <span className='content-filters-list-item__info__title'>{content}</span>
                          </div>
                          <div className='content-filters-list-item__tags'>
                            {contentFilterState(content).map((tag: string, index) => (
                              <span>
                                {tag}
                                {contentFilterState(content).length - 1 !== index && ','}
                              </span>
                            ))}
                          </div>

                          {contentFilterState(content).length > 0 ? (
                            <RemoveIcon onClick={(event) => removeFilter(event, content)} />
                          ) : (
                            <AddIcon />
                          )}
                        </div>
                      </Popover>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Content = (props: Props) => {
  return (
    <Row>
      <Col span={6}></Col>
      <Col span={12}>
        <ContentForm {...props} />
      </Col>
      <Col span={6}>
        <StepsBanner
          heading='Refining your report'
          description='Select your main content sources—Watchlists, Narratives, or Bookmarks. Add more selections to focus your report; only items matching all your choices will be included. Finally, fine-tune with filters like Language, Platform, and Content Type.'
          icon={PuzzleIcon}
        />
      </Col>
    </Row>
  )
}

export default Content
