import classNames from 'classnames'
import { Spin, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'

import { BarChart } from './BarChart'
import { SankeyChart } from './SankeyChart'

import { store } from 'store'
import { flagsAPI } from 'api/api'

import { ReactComponent as BarChartIcon } from 'assets/images/bar-chart-icon.svg'
import { ReactComponent as SankeyDiagramIcon } from 'assets/images/sankey-diagram-icon.svg'

import { SubStore } from 'types/types'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'

import './MonitorFlags.scss'
import useDelayedLoading from 'utils/useDelayedLoading'

interface Props {
  subStore: SubStore
}
export const MonitorFlags = observer(({ subStore }: Props) => {
  const [narrativeStats, setNarrativeStats] = useState<number>(0)

  const { flagsStore, loaderStore } = store
  const {
    flagCategoriesGraphData,
    hasCategoriesConditionsFetched,
    setFlagTypeGraphData,
    setFlagCategoriesGraphData,
    setHasCategoriesConditionsFetched,
    fetchCategoriesForGraph,
    fetchDataForObject,
  } = flagsStore
  const { activeItem, snippetsFilter, fetchStats } = store[`${subStore}Store`]
  const { isLoadingAttentionFlags, isLoadingNoOfPosts } = loaderStore

  const [selectedChartName, setSelectedChartName] = useState<'sankey' | 'bar'>('sankey')
  const [selectedIndex, setSelectedIndex] = useState(snippetsFilter.flags?.name || '')
  const [selectedTopIndex, setSelectedTopIndex] = useState('')

  const isLoading = useDelayedLoading([isLoadingAttentionFlags, isLoadingNoOfPosts], 2000)

  useEffectOnce(() => {
    fetchCategoriesForGraph()
  })

  useEffect(() => {
    if (Object.keys(flagCategoriesGraphData)?.length > 0) {
      fetchNarrativeStats()
      fetchFlagTypesData()
    }
  }, [flagCategoriesGraphData, snippetsFilter])

  useEffect(() => {
    if (Object.keys(flagCategoriesGraphData)?.length > 0 && hasCategoriesConditionsFetched) {
      fetchCategoryStats()
    }
  }, [flagCategoriesGraphData, snippetsFilter, hasCategoriesConditionsFetched])

  useUnmount(() => {
    setHasCategoriesConditionsFetched(false)
  })

  const fetchNarrativeStats = async () => {
    const response = await fetchStats(undefined, activeItem?.name, true)
    setNarrativeStats(response?.total_value || 0)
  }

  const fetchCategoryStats = async () => {
    const promises = Object.keys(flagCategoriesGraphData)?.map((key) => {
      const category = flagCategoriesGraphData[key]
      if (Object.keys(category.conditions || {})?.length === 0)
        return Promise.resolve({
          conditions: category.conditions,
          name: category.name,
          total_value: 0,
        })
      else return fetchStats(category.conditions || {}, key, true)
    })
    const responseArray = await Promise.all(promises)
    responseArray.forEach((response) => {
      if (response) setFlagCategoriesGraphData(response.name, { total_value: response.total_value })
    })
  }

  const fetchFlagTypesData = async () => {
    try {
      const promises = Object.keys(flagCategoriesGraphData)?.map(async (key) => {
        const category = flagCategoriesGraphData[key]
        let requestParams: FlagListParamsType = {
          q: `category_id:eq:${category.category_id}`,
        }

        const { data } = await flagsAPI.getFlagsList({ params: requestParams })
        const activeFlags = data?.filter((item: any) => item.is_active === true) || []

        if (activeFlags.length > 0) {
          const updatedItems = await Promise.all(
            activeFlags?.map(async (obj: FlagListObjType) => {
              if (obj.name === selectedIndex) setFlagCategoriesGraphData(category.name, { expanded: true })

              return await fetchDataForObject(obj, 'flag')
            }) || [],
          )
          const graphData = await Promise.all(
            updatedItems.map((item) => {
              if (Object.keys(item.conditions)?.length === 0)
                return Promise.resolve({ conditions: item.conditions, name: item.name, total_value: 0 })
              else return fetchStats(item.conditions, item.name, true)
            }),
          )

          setFlagTypeGraphData(category.name, graphData)
        }
      })

      Promise.all(promises)
    } catch (error: any) {
      console.log(error)
    }
  }

  const changeChart = (name: 'sankey' | 'bar') => {
    setSelectedChartName(name)
  }

  return (
    <Spin spinning={isLoading} style={{ minHeight: '350px' }}>
      <div className='monitor-flags__container'>
        <div className='monitor-flags__header-div'>
          <div className='monitor-flags__header-div__title-div'>
            <span className='monitor-flags__header-div__title'>{'Risk Flags'}</span>
            <span className='monitor-flags__header-div__description'>
              {
                'Represent repeatable risks or opportunities that allow you to quickly focus on the content that is most important to you'
              }
            </span>
          </div>
          <div className='monitor-flags__header-div__type-div'>
            <div
              className={classNames('monitor-flags__header-div__type-div__sankey-icon', {
                'monitor-flags__header-div__type-div__sankey-icon--active': selectedChartName === 'sankey',
              })}
              onClick={() => changeChart('sankey')}>
              <Tooltip placement='bottom' title='Sankey Diagram'>
                <SankeyDiagramIcon aria-label='Sankey Diagram' />
              </Tooltip>
            </div>
            <div
              className={classNames('monitor-flags__header-div__type-div__bar-icon', {
                'monitor-flags__header-div__type-div__bar-icon--active': selectedChartName === 'bar',
              })}
              onClick={() => changeChart('bar')}>
              <Tooltip placement='bottom' title='Bar Chart'>
                <BarChartIcon aria-label='Bar Chart' />
              </Tooltip>
            </div>
          </div>
        </div>
        {selectedChartName === 'bar' ? (
          <BarChart
            subStore={subStore}
            narrativeStats={narrativeStats}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setSelectedTopIndex={setSelectedTopIndex}
          />
        ) : (
          <SankeyChart
            subStore={subStore}
            flagCategoriesData={flagCategoriesGraphData}
            narrativeStats={narrativeStats}
            selectedIndex={selectedIndex}
            selectedTopIndex={selectedTopIndex}
            setSelectedIndex={setSelectedIndex}
            setSelectedTopIndex={setSelectedTopIndex}
          />
        )}
      </div>
    </Spin>
  )
})
