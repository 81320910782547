import { observer } from 'mobx-react-lite'
import { Avatar } from 'antd'
import { store } from 'store'
import { AssetListObjType } from 'store/asset/types'
import profileImage from 'assets/images/LogoiconMobile.svg'
import classNames from 'classnames'
import { ReactComponent as AddIcon } from 'assets/images/add-icon-grey.svg'

import './BrandsListVertical.scss'

type PropsType = {
  showBrandList: boolean
  selectedBrand: AssetListObjType | undefined
  setSelectedBrand: (brand: AssetListObjType) => void
}
const BrandsListVertical = observer((props: PropsType) => {
  const { setSelectedBrand, selectedBrand, showBrandList } = props
  const { assetsStore } = store
  const { assetsList } = assetsStore

  const onChangeBrandSelection = (brand: AssetListObjType) => setSelectedBrand(brand)

  return (
    <div
      className={classNames('brandListVertical', {
        hideBrandList: !showBrandList,
      })}>
      <div className='fixed'>
        {assetsList.items.map((asset) => (
          <div
            onClick={() => onChangeBrandSelection(asset)}
            className={classNames(`brand-item`, {
              'brand-item-selected': selectedBrand?.id === asset.id,
            })}>
            <Avatar src={profileImage} />
            <h6 className='title'>{asset.name}</h6>
          </div>
        ))}
        <div className='add-new-brand'>
          <AddIcon /> Add new brand
        </div>
      </div>
    </div>
  )
})

export default BrandsListVertical
