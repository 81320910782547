import { Form } from 'antd'
import { useEffect, useState } from 'react'

import Preview from 'components/Vectors/CreateEditWatchListSteps/Preview'
import AddLinks from 'components/Vectors/CreateEditWatchListSteps/AddLinks'
import AddDetails from 'components/Vectors/CreateEditWatchListSteps/AddDetails'
import CreateEditWatchlistNavbar from 'components/Vectors/CreateEditWatchlistNavbar'
import CreateEditWatchlistHeader from 'components/Vectors/CreateEditWatchlistHeader'
import CreateEditWatchlistFooter from 'components/Vectors/CreateEditWatchlistFooter'

import { ReactComponent as BetaTag } from 'assets/images/beta-icon.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

import './CreateEditWatchListStepper.scss'
import { store } from 'store'

const betaTag = {
  bluesky: <BetaTag className={'betaTag'} />,
  linkedin: <BetaTag className={'betaTag'} />,
}

const CreateEditWatchListStepper = () => {
  const [activeStep, setActiveStep] = useState<number>(1)

  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})

  const { verifyChannelURL } = store.defineWatchlistStore

  const totalStep = 3
  const cancelButtonText = activeStep === 1 ? 'Cancel' : 'Back'
  const continueButtonText = activeStep === 3 ? 'Publish Watchlist' : 'Continue'

  const handleNextStep = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()
      setFormData((prevData) => ({ ...prevData, ...values }))
      if (activeStep === totalStep) {
        console.log('Finished')
      }
      setActiveStep((prev) => (prev === totalStep ? prev : prev + 1))
    } catch (error) {
      console.log('Validation Failed:', error)
    }
  }

  const onCancelClick = () => {
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    setActiveStep((prev) => (prev === 1 ? prev : prev - 1))
  }

  const verifyAndGetPlatform = async () => {
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    if (values?.url) {
      try {
        await verifyChannelURL({ url: values.url })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const stepTitle = () => {
    switch (activeStep) {
      case 1:
        return 'Add Watchlist details'
      case 2:
        return 'Add links'
      case 3:
        return 'Preview watchlist'
      default:
        return 'Add Watchlist details'
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, form])

  return (
    <div className='watchlist-builder'>
      <Form form={form} layout='vertical' initialValues={formData}>
        <div className='watchlist-builder__section watchlist-builder__section--left'></div>
        <div className='watchlist-builder__section watchlist-builder__section--center'>
          <CreateEditWatchlistNavbar stepTitle={stepTitle()} step={activeStep} totalSteps={totalStep} />
          {activeStep !== 1 && <CreateEditWatchlistHeader verifyAndGetPlatform={verifyAndGetPlatform} />}
          <div className='watchlist-builder__section--center__form'>
            {activeStep === 1 && <AddDetails />}
            {activeStep === 2 && <AddLinks formData={formData} />}
            {activeStep === 3 && <Preview />}
          </div>
          <CreateEditWatchlistFooter
            onCancel={onCancelClick}
            onContinue={handleNextStep}
            cancelButtonText={cancelButtonText}
            continueButtonText={continueButtonText}
          />
        </div>
        <div className='watchlist-builder__section watchlist-builder__section--right'>
          <div className='label'>Platforms supported</div>
          <div className='platforms'>
            {PLATFORM_ICONS_GREY?.map((item, index) => (
              <span className='platform' key={`${item.title}${index}right`}>
                {item.icon}
                {item.title}
                {betaTag[item.title.toLowerCase() as keyof typeof betaTag] || null}
              </span>
            ))}
          </div>
        </div>
      </Form>
    </div>
  )
}

export default CreateEditWatchListStepper
