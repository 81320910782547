import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Spin, Tooltip } from 'antd'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import VectorsListChunk from './VectorsListChunk/VectorsListChunk'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import MoveToFolderModal from 'components/Monitor/MoveToFolderModal/MoveToFolderModal'
import { UtilService } from 'services/Util/Util'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as FlagIcon } from 'assets/images/icons/flag/flag_icon.svg'
import { ReactComponent as AddIcon } from 'assets/images/icons/monitor/add_icon.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import {
  MoveTopFolderDataType,
  NarrativeListDataType,
  NarrativeParamsType,
  NarrativesResponseType,
  SelectedTabType,
} from 'types/types'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import './VectorsListSection.scss'
import { useEffectOnce } from 'react-use'
import TagManager from 'react-gtm-module'

type ActionButtonEventType = 'ADD' | 'CREATE_FLAG' | 'ALERT' | 'REPORT' | 'SELECT_ALL' | 'CANCEL'

type ActionButtonObjType = {
  id: string
  title: string
  icon: React.ElementType | undefined
  eventName: ActionButtonEventType
  disabled: boolean
  elementTitle?: string
}

const actionButtons: ActionButtonObjType[] = [
  { id: '0', title: 'Add to List', icon: AddIcon, eventName: 'ADD', disabled: false },
  { id: '1', title: 'Alerts', icon: AlertIcon, eventName: 'ALERT', disabled: true, elementTitle: 'Create an Alert' },
  {
    id: '2',
    title: 'Report',
    icon: ReportIcon,
    eventName: 'REPORT',
    disabled: true,
    elementTitle: 'Create an AI-Assisted Report',
  },
  { id: '3', title: 'Create flag', icon: FlagIcon, eventName: 'CREATE_FLAG', disabled: true },
  { id: '4', title: 'Select all', icon: undefined, eventName: 'SELECT_ALL', disabled: true },
  { id: '5', title: 'Cancel', icon: undefined, eventName: 'CANCEL', disabled: false },
]

const entityType = { narratives: 'NARRATIVE', watchlists: 'WATCHLIST' }

type VectorsListSectionProps = {
  selectedObj: NarrativeListDataType | null
  setSelectedObj: (obj: NarrativeListDataType | null) => void
  activeCollection: string
  openMoveToModal: boolean
  setOpenMoveToModal: (flag: boolean) => void
  openMoveToModalForDetail: boolean
  setOpenMoveToModalForDetail: (flag: boolean) => void
}

type VectorsTabType = {
  id: string
  key: SelectedTabType
  name: string
}

const VectorsListSection = observer((props: VectorsListSectionProps) => {
  const { vectorsStore, toasterStore, breadcrumbStore, tenantsStore, userStore } = store
  const {
    userFolderList,
    paginationValues,
    narrativeLoading,
    narrativesListData,
    communitiesListData,
    watchlistListData,
    selectedTab,
    resetSnippetFilters,
    setSelectedTab,
    setPaginationValues,
    fetchConditionsPayloadForAssets,
    fetchNarratives,
    fetchCommunities,
    fetchWatchlist,
    fetchFolderList,
    CDUFolder,
    moveToFolder,
  } = vectorsStore
  const { userId, userName, tenantId, roleId } = userStore
  const { showToaster } = toasterStore
  const { rootPage, setBreadcrumbData } = breadcrumbStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const {
    selectedObj,
    setSelectedObj,
    activeCollection,
    openMoveToModal,
    setOpenMoveToModal,
    openMoveToModalForDetail,
    setOpenMoveToModalForDetail,
  } = props

  const [searchValue, setSearchValue] = useState<string>('')
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<NarrativeListDataType[]>([])

  const narrativeText = changeLabelTextBasedOnTheme('Narratives', isCorporateCommunicationsTheme)
  const watchListText = changeLabelTextBasedOnTheme('Watchlists', isCorporateCommunicationsTheme)

  const tabButtonsData: VectorsTabType[] = [
    { id: '0', key: 'narratives', name: narrativeText },
    { id: '1', key: 'watchlists', name: watchListText },
  ]

  useEffectOnce(() => {
    resetSnippetFilters()
  })

  useEffect(() => {
    const requestParams: NarrativeParamsType = {
      page: 1,
      per_page: paginationValues.pageSize,
      sort: 'name:asc',
      // in_library: false,
      vector_lib: true,
    }

    if (searchValue) {
      requestParams['q'] = `name:like:${searchValue}`
    }
    if (activeCollection) {
      requestParams['folder_id'] = activeCollection
    }
    setPaginationValues({ page: 1, pageSize: paginationValues.pageSize })
    if (selectedTab === 'narratives') {
      fetchNarratives({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    } else if (selectedTab === 'communities') {
      fetchCommunities({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    } else if (selectedTab === 'watchlists') {
      fetchWatchlist({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    }
  }, [selectedTab, activeCollection])

  useEffect(() => {
    if (selectedTab && rootPage) {
      const newBreadcrumbData = [
        { id: UtilService.generateUuid(), title: rootPage },
        {
          id: UtilService.generateUuid(),
          title: changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme),
        },
      ]

      setBreadcrumbData(newBreadcrumbData)
    }
  }, [selectedTab, rootPage, isCorporateCommunicationsTheme])

  useEffect(() => {
    const params = {
      is_asset: true,
    }
    Promise.all([fetchConditionsPayloadForAssets(params), fetchFolderList({})])
  }, [])

  const renderListData: NarrativesResponseType = useMemo(() => {
    if (selectedTab === 'communities') {
      return communitiesListData
    }
    if (selectedTab === 'watchlists') {
      return watchlistListData
    }
    return narrativesListData
  }, [selectedTab, narrativesListData, communitiesListData, watchlistListData])

  const searchHandler = (value: string, prevValue: string) => {
    if (value || prevValue) {
      setSelectedItems([])
      setPrevSearchValue(searchValue)
      let requestParams: NarrativeParamsType = {
        page: 1,
        per_page: paginationValues.pageSize,
        sort: 'name:asc',
        // in_library: false,
        vector_lib: true,
      }
      if (value) {
        requestParams['q'] = `name:like:${value}`
      }
      if (activeCollection) {
        requestParams['folder_id'] = activeCollection
      }
      setPaginationValues({ page: 1, pageSize: paginationValues.pageSize })
      if (selectedTab === 'narratives') {
        fetchNarratives({ params: requestParams }).then((res) => {
          if (res !== 'error') {
            if (res.items.length) {
              setSelectedObj(res.items[0])
            } else {
              setSelectedObj(null)
            }
          }
        })
      }
      if (selectedTab === 'communities') {
        fetchCommunities({ params: requestParams }).then((res) => {
          if (res !== 'error') {
            if (res.items.length) {
              setSelectedObj(res.items[0])
            } else {
              setSelectedObj(null)
            }
          }
        })
      }
      if (selectedTab === 'watchlists') {
        fetchWatchlist({ params: requestParams }).then((res) => {
          if (res !== 'error') {
            if (res.items.length) {
              setSelectedObj(res.items[0])
            } else {
              setSelectedObj(null)
            }
          }
        })
      }
    }
  }

  const fetchListHandler = () => {
    const requestParams: NarrativeParamsType = {
      page: paginationValues.page,
      per_page: paginationValues.pageSize,
      sort: 'name:asc',
      // in_library: false,
      vector_lib: true,
    }

    if (searchValue) {
      requestParams['q'] = `name:like:${searchValue}`
    }
    if (activeCollection) {
      requestParams['folder_id'] = activeCollection
    }
    setPaginationValues({ page: 1, pageSize: paginationValues.pageSize })
    if (selectedTab === 'narratives') {
      fetchNarratives({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    } else if (selectedTab === 'communities') {
      fetchCommunities({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    } else if (selectedTab === 'watchlists') {
      fetchWatchlist({ params: requestParams }).then((res) => {
        if (res !== 'error') {
          if (res.items.length) {
            setSelectedObj(res.items[0])
          } else {
            setSelectedObj(null)
          }
        }
      })
    }
  }

  const actionButtonsHandler = (eventName: ActionButtonEventType) => {
    if (eventName === 'CANCEL') {
      setSelectedItems([])
    }
    if (eventName === 'ADD') {
      setOpenMoveToModal(true)
    }
  }

  const moveToFolderhandler = (id: string) => {
    if (id && selectedTab !== 'communities') {
      const requestBody: MoveTopFolderDataType = {
        action: 'add',
        entities: [
          {
            entity_id: openMoveToModalForDetail ? selectedObj?.id! : selectedItems[0].id,
            entity_type: entityType[selectedTab as keyof typeof entityType] as 'NARRATIVE' | 'WATCHLIST',
          },
        ],
      }

      moveToFolder({ id, data: requestBody }).then((res) => {
        if (res !== 'error') {
          fetchListHandler()
          showToaster({
            iconType: 'success',
            message: 'Successfully added items to your Folder',
          })
        }
        setSelectedItems([])
        setOpenMoveToModal(false)
        setOpenMoveToModalForDetail(false)
        TagManager.dataLayer({
          dataLayer: {
            event: 'add_to_folder',
            //@ts-ignore
            user_id: userId,
            user_name: userName,
            tenantId: tenantId,
            roleId: roleId,
            entityId: openMoveToModalForDetail ? selectedObj?.id! : selectedItems[0].id,
            entityType: entityType[selectedTab] as 'NARRATIVE' | 'WATCHLIST',
            folderId: id,
          },
        })
      })
    }
  }

  const createFolderHandler = (value: string) => {
    if (value) {
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
      })
    }
  }
  return (
    <Spin size='large' spinning={narrativeLoading} wrapperClassName='vlsc_spinner_wrapper' className='vlsc_spinner'>
      <div className='vectors_list_section_container'>
        <div className='vlsc_header_container'>
          <div className='vlsc_hc_search_container'>
            <SearchInput
              className='vlsc_hc_search'
              value={searchValue}
              placeholder={`Search from ${renderListData.total_count || 0} results`}
              prefix={SearchIcon}
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={() => searchHandler(searchValue, prevSearchValue)}
              onClear={() => {
                setSearchValue('')
                searchHandler('', prevSearchValue)
              }}
            />
          </div>
          {selectedItems.length ? (
            <div className='vlsc_hc_bottom_container vlsc_hc_bottom_action_container'>
              <div className='vlsc_hc_bc_buttons'>
                {actionButtons.map((btn) => (
                  <Tooltip placement='bottom' title={btn.elementTitle}>
                    <ButtonImage
                      key={btn.id}
                      icon={btn.icon}
                      aria-label={btn.elementTitle || ''}
                      className='vlsc_hc_bc_button'
                      onClick={() => {
                        actionButtonsHandler(btn.eventName)
                      }}
                      disabled={btn.disabled || (btn.eventName === 'ADD' && selectedItems[0]?.present_in_folder)}>
                      {btn.title}
                    </ButtonImage>
                  </Tooltip>
                ))}
              </div>
            </div>
          ) : (
            <div className='vlsc_hc_bottom_container'>
              <div className='vlsc_hc_bc_buttons'>
                {tabButtonsData.map((tab) => (
                  <ButtonImage
                    key={tab.id}
                    className={`vlsc_hc_bc_button${selectedTab === tab.key ? ' vlsc_hc_bc_button_active' : ''}`}
                    onClick={() => {
                      setSelectedTab(tab.key)
                      setSelectedObj(null)
                      setSelectedItems([])
                    }}>
                    {tab.name}
                  </ButtonImage>
                ))}
              </div>
              <div className='vlsc_hc_bc_date_filter_container'>
                <DateFilter
                  days={[1, 7, 30, 90]}
                  pageType={'details'}
                  subStore={'vectors'}
                  addCustomDateOption={false}
                />
              </div>
            </div>
          )}
        </div>
        <div className='vlsc_list_container'>
          {renderListData.items.map((obj) => (
            <VectorsListChunk
              key={obj.id}
              isActive={selectedItems.some((item) => item.id === obj.id)}
              highlighted={selectedObj?.id === obj.id}
              checkBoxHandler={() => {
                setSelectedItems([obj])
              }}
              onClick={() => setSelectedObj(obj)}
              data={obj}
            />
          ))}
        </div>
        <div className='vlsc_pagination_container'>
          <DefaultPagination
            className='vlsc_pagination'
            totalCount={renderListData.total_count}
            currentPage={paginationValues.page}
            pageSize={paginationValues.pageSize}
            showPageSizeChanger={false}
            // pageSizeOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            nextIcon={ArrowNextIcon}
            prevIcon={ArrowNextIcon}
            iconHeight={20}
            iconWidth={20}
            onChange={(page, pageSize) => {
              let requestParams: NarrativeParamsType = {
                page,
                per_page: pageSize,
                sort: 'name:asc',
                vector_lib: true,
              }
              if (searchValue) {
                requestParams['q'] = `name:like:${searchValue}`
              }
              if (activeCollection) {
                requestParams['folder_id'] = activeCollection
              }
              setPaginationValues({ page, pageSize })
              if (selectedTab === 'narratives') {
                fetchNarratives({ params: requestParams }).then((res) => {
                  if (res !== 'error') {
                    if (res.items.length) {
                      setSelectedObj(res.items[0])
                    } else {
                      setSelectedObj(null)
                    }
                  }
                })
              }
              if (selectedTab === 'communities') {
                fetchCommunities({ params: requestParams }).then((res) => {
                  if (res !== 'error') {
                    if (res.items.length) {
                      setSelectedObj(res.items[0])
                    } else {
                      setSelectedObj(null)
                    }
                  }
                })
              }
              if (selectedTab === 'watchlists') {
                fetchWatchlist({ params: requestParams }).then((res) => {
                  if (res !== 'error') {
                    if (res.items.length) {
                      setSelectedObj(res.items[0])
                    } else {
                      setSelectedObj(null)
                    }
                  }
                })
              }
            }}
            locale={{ items_per_page: '' }}
          />
        </div>
        {(openMoveToModal || openMoveToModalForDetail) && (
          <MoveToFolderModal
            data={userFolderList.items}
            onClose={() => {
              setOpenMoveToModal(false)
              setOpenMoveToModalForDetail(false)
            }}
            onConfirm={moveToFolderhandler}
            onCreate={createFolderHandler}
          />
        )}
      </div>
    </Spin>
  )
})

export default VectorsListSection
