import { observer } from 'mobx-react-lite'
import IssueCard from './IssueCard'
import { useEffect, useMemo, useState } from 'react'
import { Pagination } from 'antd'
import { store } from 'store'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'
import { AssetListObjType } from 'store/asset/types'
import { SnippetFilterPayload } from 'models/models'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { openNotification } from 'services/Util/openNotification'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'
import './FollowedIssues.scss'
import { NarrativeListDataType } from 'types/types'

const FollowedIssues = ({
  selectedBrand,
  onSelectFlag,
  subStore = 'assets',
}: {
  selectedBrand: AssetListObjType | NarrativeListDataType
  onSelectFlag: (flag: FlagListObjType) => void
  subStore?: 'assets' | 'vectors'
}) => {
  const { flagsStore } = store
  const { fetchFlagsListWithConditions, flagsLoading, flagsList } = flagsStore
  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    per_page: 50,
  })
  const { snippetsFilter, getSnippetFilterPayload, getDate, fetchInsightsMetrics } = store[`${subStore}Store`]
  const [isFlagsMetricsLoading, setIsFlagsMetricsLoading] = useState(false)
  const [flagsWithMetrics, setFlagsWithMetrics] = useState<any>([])

  useEffect(() => {
    const requestParams: FlagListParamsType = {
      page: 1,
      per_page: 50,
      sort: 'name:asc',
      is_active: true,
    }
    fetchFlagsListWithConditions({ params: requestParams })
  }, [])

  useEffect(() => {
    if (flagsList.items.length > 0 && selectedBrand) {
      fetchAllActiveFlagMetrics(flagsList.items)
    }
  }, [flagsList, selectedBrand, snippetsFilter.days])

  const renderConditions = useMemo(() => {
    if (selectedBrand && Object.keys((selectedBrand as AssetListObjType).conditions).length) {
      return [(selectedBrand as AssetListObjType).conditions]
    }
    return []
  }, [selectedBrand])

  const fetchMetricsForFlag = async (flag: FlagListObjType, requestParams: { q: string; measure?: string }) => {
    if (!flag.conditions) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          skip: true,
        },
      }
    }

    const requestData = { conditions: [...renderConditions, flag.conditions] }
    const results = await Promise.allSettled([
      fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
      fetchInsightsMetrics({
        params: { ...requestParams, measure: 'impression' },
        data: requestData,
        fullResponse: true,
      }),
      fetchInsightsMetrics({
        params: { ...requestParams, measure: 'engagement' },
        data: requestData,
        fullResponse: true,
      }),
    ])

    const [views, impressions, engagement] = results

    const getResultValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error' ? 0 : result.value.total_value
      }
      return 0
    }

    const viewsCount = getResultValue(views)
    const impressionsCount = getResultValue(impressions)
    const engagementCount = getResultValue(engagement)

    if (viewsCount === 0 && impressionsCount === 0 && engagementCount === 0) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          engagement: 0,
          skip: true,
        },
      }
    }

    return {
      id: flag.id,
      metrics: {
        views: viewsCount,
        impressions: impressionsCount,
        engagement: engagementCount,
        skip: false,
      },
    }
  }

  const fetchAllActiveFlagMetrics = async (flags: FlagListObjType[]) => {
    setIsFlagsMetricsLoading(true)
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)

      //use community,platform,sentiment and flags filter,
      const requestParams: { q: string; metric?: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate},risk_score:gte:0,risk_score:lte:100${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }

      // loop through all flags and fetch metric for each flag
      const promises = flags.map((flag) => fetchMetricsForFlag(flag, requestParams))
      // Wait for all promises to settle and process results
      const results = await Promise.allSettled(promises)

      const metrics: Record<string, any> = {}

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })

      const onlyFlagsWithMetrics = flags
        .map((flag) => ({ ...flag, metric: metrics[flag.id] }))
        .filter((flag) => !flag.metric.skip)

      setFlagsWithMetrics(onlyFlagsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
      setIsFlagsMetricsLoading(false)
    }
  }

  if (!flagsLoading && !isFlagsMetricsLoading && flagsWithMetrics.length === 0) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Followed issues</span>
        <div className='influencers-empty-state'>
          <InfluencerEmptyStateImage />
          <h5>Nothing to Show</h5>
          <p>
            It looks like there’s no data available for the time range you selected. Try adjusting the date range or
            check back later.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='monitor-assets__graph__title'>Followed issues</span>
      <div className='followed-issues'>
        {!flagsLoading &&
          !isFlagsMetricsLoading &&
          flagsWithMetrics.map((flag: FlagListObjType) => (
            <IssueCard flag={flag} onSelectFlag={onSelectFlag} subStore={subStore} />
          ))}
        {!flagsLoading && !isFlagsMetricsLoading && (
          <Pagination
            defaultCurrent={paginationValues.page}
            pageSize={paginationValues.per_page}
            hideOnSinglePage
            total={flagsList.total_count}
            onChange={(page) => {
              let requestParams: FlagListParamsType = {
                page,
                per_page: paginationValues.per_page,
                sort: 'name:asc',
                is_active: true,
              }
              setPaginationValues({ page, per_page: paginationValues.per_page })
              fetchFlagsListWithConditions({ params: requestParams })
            }}
          />
        )}
        {(flagsLoading || isFlagsMetricsLoading) &&
          [1, 2, 3, 4, 5].map(() => <IssueCard emptyState onSelectFlag={() => {}} />)}
      </div>
    </>
  )
}

export default observer(FollowedIssues)
