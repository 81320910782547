import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { SnippetFilterPayload } from 'models/models'

export async function getPlatformChartData({ query, powerInsightSort, conditions }: SnippetFilterPayload) {
  try {
    const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
    const queryParams = `q=${q}&sort=${powerInsightSort}`

    const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.platformChartData}?${queryParams}`

    const { data } = await axios.post(endpoint, { conditions })
    return data
  } catch (error) {
    LogService.error({ message: 'getPlatformChartData error', error })
  }
}
