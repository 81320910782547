import { Carousel } from 'antd'
import { observer } from 'mobx-react-lite'
import IssueCard from './IssueCard'
import { flagsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { AssetListObjType } from 'store/asset/types'
import { NarrativeListDataType } from 'types/types'
import { useEffect, useMemo, useState } from 'react'
import { SuggestFlagObject } from 'store/flags/types'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'
import './SuggestedIssues.scss'

type Props = {
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
}

const SuggestedIssues = ({ selectedItem }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedIssues, setSuggestedIssues] = useState([])

  useEffect(() => {
    fetchSuggestedIssues()
  }, [selectedItem])

  const fetchSuggestedIssues = () => {
    if (!selectedItem) return
    setIsLoading(true)
    flagsAPI
      .getSuggestedTopics({ id: selectedItem.id })
      .then((response) => {
        setSuggestedIssues(response.data.items)
      })
      .catch((error) => {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const chunkArray = (array: any[], chunkSize: number) => {
    const result: any[] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  // Memoizing the grouped issues
  const groupedIssues = useMemo(() => chunkArray(suggestedIssues, 3), [suggestedIssues])

  if (isLoading) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Suggested issues</span>
        <div className='carousel-wrapper'>
          <Carousel dots>
            <div>
              <div className='issues-wrapper'>
                <IssueCard emptyState />
                <IssueCard emptyState />
                <IssueCard emptyState />
              </div>
            </div>
          </Carousel>
        </div>
      </>
    )
  }

  if (!isLoading && suggestedIssues.length === 0) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Suggested issues</span>
        <div className='influencers-empty-state'>
          <InfluencerEmptyStateImage />
          <h5>Nothing to Show</h5>
          <p>
            It looks like there’s no data available for the time range you selected. Try adjusting the date range or
            check back later.
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='monitor-assets__graph__title'>Suggested issues</span>
      <div className='carousel-wrapper'>
        <Carousel dots>
          {groupedIssues.map((group, index) => (
            <div key={index}>
              <div className='issues-wrapper'>
                {group.map((issue: SuggestFlagObject) => (
                  <IssueCard key={issue.id} issue={issue} />
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default observer(SuggestedIssues)
