import CustomModal from 'components/common/CustomModal/CustomModal'
import { ReactComponent as SuccessIcon } from 'assets/images/icons/flag/success_icon.svg'

import './SuccessModal.scss'

type SuccessModalProps = {
  title?: string
  desc?: string
  onClose: () => void
  closeButtonText?: string
  Icon?: React.ElementType | null
}

const SuccessModal = (props: SuccessModalProps) => {
  const {
    onClose,
    closeButtonText = 'Close',
    title = 'Flag created successfully',
    desc = '',
    Icon = SuccessIcon,
  } = props

  return (
    <CustomModal
      icon={Icon}
      iconSize={54}
      title={title}
      firstButtonText={closeButtonText}
      onClose={onClose}
      containerClassName='sm_containerClassName'
      boxContainerClassName='sm_box_container'
      bodyClassName='sm_body_container'>
      <div className='sm_modal_body_container'>
        <p className='sm_follow_text'>{desc}</p>
      </div>
    </CustomModal>
  )
}

export default SuccessModal
