import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as CardParagraphSkeleton } from 'assets/images/icons/dashboard/card-paragraph-skeleton.svg'
import { Skeleton } from 'antd'
import { SuggestFlagObject } from 'store/flags/types'
import fallBackImage from 'assets/images/icons/dashboard/fallback-image.png'
import './SuggestedIssues.scss'

export default function IssueCard({ emptyState = false, issue }: { emptyState?: boolean; issue?: SuggestFlagObject }) {
  if (emptyState || !issue) {
    return (
      <div className='issue-card'>
        <Skeleton.Image className='skeleton-image' />
        <h6 className='skeleton-title'>
          <Skeleton paragraph />
        </h6>
        <p className='description'>
          <CardParagraphSkeleton />
        </p>
        <ButtonImage onClick={() => {}} className='fhc_sc_button follow-btn'>
          Follow Issue
        </ButtonImage>
      </div>
    )
  }

  return (
    <div className='issue-card'>
      <div className='img-wrapper'>
        <img src={issue.image_url || fallBackImage} alt='Issue' className='img' />
      </div>
      <h6 className='title'>{issue.title}</h6>
      <p className='description'>{issue.summary}</p>
      <ButtonImage onClick={() => {}} className='fhc_sc_button follow-btn' disabled>
        Follow Issue
      </ButtonImage>
    </div>
  )
}
