import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

const CreateEditWatchlistFooter = ({
  onCancel,
  onContinue,
  isCancelVisible = true,
  isContinueVisible = true,
  cancelButtonText = 'Cancel',
  isContinueDisabled = false,
  continueButtonText = 'Continue',
}: {
  onCancel: () => void
  onContinue: () => void
  isCancelVisible?: boolean
  cancelButtonText?: string
  isContinueVisible?: boolean
  continueButtonText?: string
  isContinueDisabled?: boolean
}) => {
  return (
    <div className='watchlist-builder__section--center__footer'>
      {isCancelVisible && (
        <ButtonImage onClick={onCancel} className='cancel-btn' type='button'>
          {cancelButtonText}
        </ButtonImage>
      )}
      {isContinueVisible && (
        <ButtonImage onClick={onContinue} disabled={isContinueDisabled} className='continue-btn' type='button'>
          {continueButtonText}
        </ButtonImage>
      )}
    </div>
  )
}

export default CreateEditWatchlistFooter
