import React from 'react'
import { Avatar } from 'antd'
import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar, LabelList } from 'recharts'

import profileImage from 'assets/images/LogoiconMobile.svg'

import './InfluencerCard.scss'
import millify from 'millify'

interface CustomLabelProps {
  x?: string | number
  y?: string | number
  width?: string | number
  height?: string | number
  value?: string | number
}

const CustomLabel: React.FC<CustomLabelProps> = (props) => {
  const { x, y, height, value = 0 } = props
  const xPos = typeof x === 'number' ? (value === 0 ? 25 : x - 5) : 0
  const yPos = typeof y === 'number' && typeof height === 'number' ? y + height / 2 : 0

  return (
    <text
      x={xPos}
      y={yPos}
      fill='#242930'
      textAnchor='end'
      dominantBaseline='central'
      fontSize={12}
      fontFamily='Roboto'
      fontWeight={400}>
      {millify(typeof value === 'string' ? parseInt(value) : value)}
    </text>
  )
}

const InfluencerCard = ({
  maxValue,
  value = 0,
  name = '',
  creatorCount = 0,
}: {
  maxValue: number
  value: number
  name?: string
  creatorCount?: number
}) => {
  const data = { value, name }
  return (
    <div className='influencer-card'>
      <div className='influencer-card__header'>
        <Avatar src={profileImage} size={'large'} />
        <div>
          <div className='influencer-detail__name'>{name}</div>
          <div className='influencer-detail__creator-count'>
            {creatorCount} {creatorCount > 1 ? 'creators' : 'creator'}
          </div>
        </div>
      </div>

      <ResponsiveContainer height={16}>
        <BarChart
          layout='vertical'
          data={[data]}
          margin={{
            top: 0,
            right: 40,
            left: millify(typeof value === 'string' ? parseInt(value) : value).length > 4 ? 45 : 30,
            bottom: 0,
          }}>
          <XAxis type='number' hide domain={[0, maxValue]} />
          <YAxis type='category' dataKey='name' hide />
          <Bar dataKey='value' fill='#C1D1F1' barSize={14} maxBarSize={100}>
            <LabelList dataKey='value' content={(props) => <CustomLabel {...props} />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default InfluencerCard
