import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Table, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'

import { LinkOutlined } from '@ant-design/icons'
import { ReactComponent as BetaTag } from 'assets/images/beta-icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
// import { ReactComponent as EditIcon } from 'assets/images/editicon.svg'
// import { ReactComponent as DeleteIcon } from 'assets/images/deleteicon.svg'
import { ReactComponent as NoDataIcon } from 'assets/images/no_data_icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warningicon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/sort-arrow-icon.svg'
import { ReactComponent as DefaultSortIcon } from 'assets/images/sorter-icon.svg'

import Checkbox from 'components/common/Checkbox/Checkbox'
import SkeletonTable from 'components/Monitor/PowerInsights/Creators/CreatorsList/SkeletonTable'

import { Unknown } from 'utils/commonTypes'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

interface SorterProps {
  order: SortOrder
}

type SortOrder = 'descend' | 'ascend' | null

const betaTag = {
  bluesky: <BetaTag className={'betaTag'} />,
  linkedin: <BetaTag className={'betaTag'} />,
}

type SortInfo = {
  columnName: string
  direction: SortOrder
}

const AddLinks = ({ formData }: { formData: Unknown }) => {
  // const [selectedRows, setSelectedRows] = useState<string[]>([])

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortStates, setSortStates] = useState<SortInfo>({
    columnName: 'followers',
    direction: 'descend',
  })

  console.log('currentPage', currentPage)

  const Sorter = (props: SorterProps) => {
    const { order } = props

    return order ? (
      <ArrowIcon className={classNames({ 'creators-list__table__sortIcon': order === 'ascend' })} />
    ) : (
      <DefaultSortIcon />
    )
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Unknown> | SorterResult<Unknown>[],
  ) => {
    if (!Array.isArray(sorter) && sorter.columnKey) {
      setSortStates({
        columnName: sorter.columnKey as string,
        direction: sorter.order || null,
      })
    }

    if (pagination?.current) setCurrentPage(pagination.current)
  }

  const columns = useMemo(
    () => [
      {
        title: () => <Checkbox checked onClick={() => null} />,
        key: 'id',
        dataIndex: 'channel_id',
        width: '5%',
        render: () => {
          return <Checkbox checked onClick={() => null} />
        },
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Platform
            <Sorter order={sortStates?.columnName === 'creator' ? sortStates?.direction || null : null} />
          </span>
        ),

        dataIndex: 'platform',
        key: 'platform',
        width: '50%',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'platform' ? sortStates.direction : null,
        sorter: (a: Unknown, b: Unknown) => a.followers_count - b.followers_count,
        render: (data: string) => {
          const platform = PLATFORM_ICONS_GREY?.find((item) => item.title.toLowerCase() === data?.toLowerCase())
          return (
            <span className='platform-batch'>
              {platform?.icon}
              {platform?.title}
              {betaTag[platform?.title?.toLowerCase() as keyof typeof betaTag] || null}
            </span>
          )
        },
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Creator
            <Sorter order={sortStates?.columnName === 'creator' ? sortStates?.direction || null : null} />
          </span>
        ),
        dataIndex: 'creator',
        key: 'creator',
        width: '15%',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'creator' ? sortStates.direction : null,
        sorter: (a: Unknown, b: Unknown) => a.followers_count - b.followers_count,
        render: (data: Unknown) => {
          return (
            <span>
              {data.name ? (
                <span className='channel-info'>
                  <img src={data?.profile_url} alt='' />
                  <span className='channel-info__description'>
                    <span className='channel-info__description__name'>{data?.name || ''}</span>
                    <span className='channel-info__description__url'>{data?.url || ''}</span>
                  </span>
                </span>
              ) : (
                <span className={classNames('create-url', { invalid: !data?.isValid })}>
                  {data?.isValid && <LinkOutlined />}
                  {!data?.isValid && <InfoIcon />}
                  {data?.url}
                  {/* {!data?.isValid && (
                    <span className='create-url__icon' onClick={() => console.log('edited')}>
                      <EditIcon />
                    </span>
                  )}
                  {!data?.isValid && (
                    <span className='create-url__icon' onClick={() => console.log('deleted')}>
                      <DeleteIcon />
                    </span>
                  )} */}
                </span>
              )}
            </span>
          )
        },
      },
    ],
    [],
  )

  return (
    <>
      {formData?.url ? (
        <div className='add-links'>
          <div className='add-links__label'>You added {22} links:</div>
          <div className='add-links__search-input'>
            <Input size='large' placeholder='Search' prefix={<SearchOutlined />} />
            <div className='add-links__search-input__applied-platform'>
              <span className='add-links__search-input__applied-platform__all-platform'>All Platforms</span>
              {PLATFORM_ICONS_GREY?.map((item, index) => (
                <span
                  className='add-links__search-input__applied-platform__platform'
                  key={`${item.title}${index}center`}>
                  {item.icon}
                  {item.title}
                  {betaTag[item.title.toLowerCase() as keyof typeof betaTag] || null}
                </span>
              ))}
              <span className='add-links__search-input__applied-platform__invalid-links-counts'>
                <span>
                  <WarningIcon />
                </span>
                <span>3 invalid Links</span>
              </span>
            </div>
          </div>

          <div className='add-links__platform-table'>
            <SkeletonTable loading={false}>
              <Table
                className='creators-list__table'
                columns={columns}
                dataSource={[
                  {
                    platform: 'tiktok',
                    creator: {
                      url: 'https://socialmedia.com/username',
                      name: 'Elon Musk',
                      profile_url:
                        'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
                    },
                  },
                  {
                    platform: 'linkedin',
                    creator: {
                      url: 'https://socialmedia.com/username',
                      isValid: false,
                      // name: 'Jane Doe',
                      // profile_url:
                      //   'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=600',
                    },
                  },
                ]}
                onChange={handleTableChange}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                onRow={() => {
                  return {
                    onClick: (event) => {},
                  }
                }}
              />
            </SkeletonTable>
          </div>
        </div>
      ) : (
        <div className='no-data-screen'>
          <div className='no-data-screen__icon'>
            <NoDataIcon />
          </div>
          <div className='no-data-screen__text'>
            <div className='no-data-screen__text__title'>No links have been added yet</div>
            <div className='no-data-screen__text__description'>Add links to get started.</div>
          </div>
        </div>
      )}
    </>
  )
}

export default AddLinks
