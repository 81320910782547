import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { store } from 'store'
import { PlatformDistribution } from 'components/Monitor/PowerInsights/Summary/PlatformDistribution/PlatformDistribution'
import { DownloadOption } from '../DownloadOption'
import { GenericDispatch } from 'utils/commonTypes'

const PlatformDistributionCard = ({
  isVerticalDisplay = false,
  subStore = 'assets',
  setIsOpenSnippetsDiscovery,
}: {
  isVerticalDisplay?: boolean
  subStore?: 'assets' | 'vectors'
  setIsOpenSnippetsDiscovery?: GenericDispatch<boolean>
}) => {
  const { isLoadingPlatformChart } = store.loaderStore
  const { setSnippetsFilter, snippetsFilter, defaultPlatforms, setSnippetsFilterInfo, snippetsFilterInfo } =
    store[`${subStore}Store`]

  const selectedPlatforms = useMemo(() => {
    if (snippetsFilter?.filter_platform?.length === 0) return []
    return typeof snippetsFilter.filter_platform === 'string'
      ? snippetsFilter?.filter_platform?.split(',')
      : snippetsFilter?.filter_platform || []
  }, [snippetsFilter?.filter_platform])

  const onPlatformClick = (platforName: string) => {
    if (selectedPlatforms.length === 1 && selectedPlatforms[0] === platforName) {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_platform: defaultPlatforms,
      })
      const filter = { platform: 0 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_platform: platforName.toLowerCase(),
      })
      const filter = { platform: 1 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
      setIsOpenSnippetsDiscovery?.(true)
    }
  }

  return (
    <div id='platformChart'>
      <Spin spinning={isLoadingPlatformChart}>
        <div className='chart-heading'>
          <span className='monitor-assets__graph__title'>Platforms</span>
          <DownloadOption elementToCapture='#platformChart' />
        </div>
        <PlatformDistribution
          subStore={subStore}
          isShowLabel={false}
          showEmptyState
          onCellClick={onPlatformClick}
          selectedPlatform={selectedPlatforms.length === 1 ? selectedPlatforms[0] : null}
          isVerticalDisplay={isVerticalDisplay}
        />
      </Spin>
    </div>
  )
}

export default observer(PlatformDistributionCard)
