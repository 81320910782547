import axios from 'axios'

import { ROUTES } from 'settings/settings'

type Channel = {
  platform: string
  channel_urls: string[]
}

export interface WatchlistProps {
  name: string
  description: string
  channels: Channel[]
}

export const saveWatchlist = async (watchlist: WatchlistProps) => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist
  const { data } = await axios.post(url, watchlist)

  return data
}

export const verifyChannelURL = async (payload: { url: string }) => {
  let url = process.env.REACT_APP_API + ROUTES.validateChannelURL
  const { data } = await axios.post(url, payload)
  return data
}
